import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
    <Layout>
        <SEO title="Privacy"/>
        <h1>Privacy any</h1>
        <p>description any</p>
        <Link to="/">Go back to the homepage</Link>
    </Layout>
)

export default PrivacyPage
